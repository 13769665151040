import * as React from "react";
import { Brand, Button, DataListAction, DataListCell, DataListItem, DataListItemCells, DataListItemRow } from "@patternfly/react-core";
import { Divider, Dropdown, DropdownItem, DropdownList, MenuToggle } from "@patternfly/react-core";
import { EditIcon, EllipsisVIcon, TagIcon, TimesIcon } from "@patternfly/react-icons";

// category =
// {
//   "id": 1,
//   "organization_id": 1,
//   "name": "Hard worker",
//   "icon_url": "https://cdn.iconscout.com/icon/premium/png-512-thumb/search-8116688-6636445.png?f=webp&w=256",
//   "points": 50,
//   "description": "working hard"
// }

export const CategoryDataListItem = ({ category, isOpen, setIsOpen, onSelect, onToggle, onUpdate, onDelete }) => {

  return (
    <DataListItem key={category.id} aria-labelledby={category.id}>
      <DataListItemRow>
        <DataListItemCells
          dataListCells={[
            <DataListCell key="icon" isFilled={false}><TagIcon /></DataListCell>,
            <DataListCell key="image" isFilled={false}><Brand src={category.icon_url} alt="?" widths={{ default: '18px' }} /></DataListCell>,
            <DataListCell key="name">
              <Button size="sm" variant="link" isInline onClick={() => onUpdate(category?.id)}>{category?.name}</Button>
            </DataListCell>,
            <DataListCell key="description">{category.description}</DataListCell>,
            <DataListCell key="points">Points: {category.points}</DataListCell>
          ]}
        />
        <DataListAction isPlainButtonAction>
          <Dropdown
            popperProps={{ position: 'right' }}
            onOpenChange={(isOpen) => { if (isOpen) setIsOpen(category.id); else setIsOpen(0) }}
            onSelect={onSelect}
            isOpen={(isOpen === category.id)}
            toggle={(toggleRef) => (
              <MenuToggle
                ref={toggleRef}
                isExpanded={isOpen === category.id}
                onClick={() => onToggle(category.id)}
                variant="plain"
                aria-label="Category Item Action Menu"
              >
                <EllipsisVIcon aria-hidden="true" />
              </MenuToggle>
            )}
          >
            <DropdownList>
              <DropdownItem key="edit" icon={<EditIcon />} onClick={() => onUpdate(category.id)}>Edit</DropdownItem>
              <Divider key="sep1"></Divider>
              <DropdownItem key="delete" icon={<TimesIcon />} onClick={() => onDelete(category.id)}>Delete</DropdownItem>
            </DropdownList>
          </Dropdown>
        </DataListAction>
      </DataListItemRow>
    </DataListItem>
  );

};
