import * as React from "react";
import { Breadcrumb, BreadcrumbItem, Bullseye, Button } from "@patternfly/react-core";
import { DataList } from "@patternfly/react-core";
import { Icon, PageBreadcrumb, PageGroup, PageSection } from "@patternfly/react-core";
import { Spinner, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem, Tooltip } from "@patternfly/react-core";
import { ClipboardCheckIcon, PlusIcon, SyncIcon } from "@patternfly/react-icons";

import { get } from "../../api/api";
import { OrganizationContext } from "../../common/contexts/OrganizationContext";
import { PageHeadingComponent } from "../../components/PageHeadingComponent";
import { Permission } from "./Permission";
import { PermissionDataListItem } from "./PermissionDataListItem";

const Permissions = () => {

  const organization = React.useContext(OrganizationContext);

  const [loading, setLoading] = React.useState(true);
  const [permissions, setPermissions] = React.useState([]);
  const [permission, setPermission] = React.useState(null);
  const [action, setAction] = React.useState("create");
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const [isActionOpen, setIsActionOpen] = React.useState(0);

  const fetchPermissions = async () => {
    setLoading(true);
    try {
      const response = await get(organization.id, `/permissions/getPermissions`);
      setPermissions(response.data);
      setLoading(false);
    } catch (error) {
      console.error(JSON.stringify(error));
      setLoading(false);
    }
  };

  React.useEffect(() => {

    const firstFetch = async () => {
      fetchPermissions();
    };

    if (organization?.id) {
      firstFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization.id]);

  const onActionSelect = () => {
    setIsActionOpen(0);
  };

  const onActionToggle = (id) => {
    if (isActionOpen === id)
      setIsActionOpen(0)
    else
      setIsActionOpen(id);
  };

  const onModalToggle = (e) => {
    setIsModalOpen(!isModalOpen);
  };

  const onAddPermission = () => {
    setPermission(null);
    setAction("create");
    onModalToggle();
  };

  const onClose = () => {
    onModalToggle();
  };

  const onDeletePermission = (id) => {
    setPermission(permissions.find(item => item.id === id));
    setAction("delete");
    onModalToggle();
  };

  const onEditPermission = (id) => {
    setPermission(permissions.find(item => item.id === id));
    setAction("update");
    onModalToggle();
  };

  const onRefreshPermissions = () => {
    fetchPermissions();
  };

  const onRefreshPermission = (updatedPermission) => {
    onRefreshPermissions();
    onModalToggle();
  };

  const onViewPermission = (id) => {
    setPermission(permissions.find(item => item.id === id));
    setAction("view");
    onModalToggle();
  };

  const renderToolbar = (
    <Toolbar>
      <ToolbarContent>
        <ToolbarGroup>
          <ToolbarItem variant="label">
            Permissions
          </ToolbarItem>
        </ToolbarGroup>
        <ToolbarGroup align={{default: 'alignRight' }}>
          <ToolbarItem variant="separator" />
          <ToolbarItem>
            <Tooltip content="Create new Permission">
              <Button variant="plain" onClick={onAddPermission}><Icon><PlusIcon /></Icon></Button>
            </Tooltip>
          </ToolbarItem>
          <ToolbarItem>
            <Tooltip content="Refresh List">
              <Button variant="plain" onClick={onRefreshPermissions}><Icon><SyncIcon /></Icon></Button>
            </Tooltip>
          </ToolbarItem>
        </ToolbarGroup>
      </ToolbarContent>
    </Toolbar>
  );

  if (loading) {
    return (
      <Bullseye height="100vh">
        <Spinner size="xl" />
      </Bullseye>
    );
  }

  return (
    <React.Fragment>
      <PageGroup stickyOnBreakpoint={{ default: 'top' }}>
        <PageBreadcrumb>
          <Breadcrumb>
            <BreadcrumbItem to="/">Dashboard</BreadcrumbItem>
            <BreadcrumbItem isActive>Administration</BreadcrumbItem>
            <BreadcrumbItem isActive>Permissions</BreadcrumbItem>
          </Breadcrumb>
        </PageBreadcrumb>
        <PageHeadingComponent title="Permissions" subtitle="Administer the permissions used in the application." icon={<Icon><ClipboardCheckIcon /></Icon>} />
      </PageGroup>
      <PageSection>
        {
          renderToolbar
        }
        <DataList isCompact>
          {
            permissions.map((item) => (
              <PermissionDataListItem
                key={item.id}
                permission={item}
                isOpen={isActionOpen}
                setIsOpen={setIsActionOpen}
                onSelect={onActionSelect}
                onToggle={onActionToggle}
                onEdit={onEditPermission}
                onDelete={onDeletePermission}
                onView={onViewPermission}
              />
            ))
          }
        </DataList>
      </PageSection>
      <Permission isOpen={isModalOpen} onClose={onClose} permission={permission} organizationId={organization.id} action={action} onRefresh={onRefreshPermission} />
    </React.Fragment>
  );

};

export {Permissions};
