import * as React from "react";
import { Bullseye, Button, Chip, ChipGroup } from "@patternfly/react-core";
import { MenuToggle, Select, SelectList, SelectOption } from "@patternfly/react-core";
import { TextInputGroup, TextInputGroupMain, TextInputGroupUtilities } from "@patternfly/react-core";
import { TimesIcon } from "@patternfly/react-icons";

import { get } from "../api/api";
import { OrganizationContext, UserContext } from "../common/contexts";
import { AvatarComponent } from "../components/AvatarComponent";

const initialSelectOptions = [];

export const SelectUsersComponent = ({ placeholder, selectedUsers, setSelectedUsers }) => {

  const organization = React.useContext(OrganizationContext);
  const organizationId = organization.id;
  const user = React.useContext(UserContext);

  const [isOpen, setIsOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [selectOptions, setSelectOptions] = React.useState(initialSelectOptions)
  const [focusedItemIndex, setFocusedItemIndex] = React.useState(null);
  const [activeItemId, setActiveItemId] = React.useState(null);
  const textInputRef = React.useRef();

  const NO_RESULTS = "no results";

  const fetchUserList = async (query) => {
    try {
      if (query.length > 0) {
        const response = await get(organizationId, `/users/searchUser/search?query=${query}`);
        if (!response.data.length) {
          setSelectOptions([{ isAriaDisabled: true, first_name: 'No Results Found', surname: '' }]);
        } else {
          const excludeIds = selectedUsers.map(obj => obj.id);
          setSelectOptions(response.data.filter(obj => !excludeIds.includes(obj.id)));
//          setSelectOptions(response.data);
        }
      }
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  };

  React.useEffect(() => {
    fetchUserList(inputValue);
    let newSelectOptions = initialSelectOptions;
    // Filter menu items based on the text input value when one exists
    if (inputValue) {
      newSelectOptions = initialSelectOptions.filter((menuItem) => 
        String(menuItem.children).toLowerCase().includes(inputValue.toLowerCase())
      );
      // When no options are found after filtering, display 'No results found'
      if (!newSelectOptions.length) {
        newSelectOptions = [
          {
            isAriaDisabled: true,
            children: `No results found for "${inputValue}"`,
            value: NO_RESULTS
          }
        ];
      }
      // Open the menu when the input value changes and the new value is not empty
      if (!isOpen) {
        setIsOpen(true);
      }
    }
    if (isOpen && inputValue.length === 0) {
      setIsOpen(false)
    }
    setSelectOptions(newSelectOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const createItemId = (value) => `select-multi-typeahead-${value.replace(' ', '-')}`;

  const setActiveAndFocusedItem = (itemIndex) => {
    setFocusedItemIndex(itemIndex);
    const focusedItem = selectOptions[itemIndex];
    setActiveItemId(createItemId(focusedItem.value));
  };

  const resetActiveAndFocusedItem = () => {
    setFocusedItemIndex(null);
    setActiveItemId(null);
  };

  const closeMenu = () => {
    setIsOpen(false);
    resetActiveAndFocusedItem();
  };

  const onInputClick = () => {
    if (!isOpen && inputValue && inputValue.length > 0) {
      setIsOpen(true);
    } else if (!inputValue) {
      closeMenu();
    }
  };

  const onSelect = (value) => {
    console.log("onSelect");
    console.log("value: " + value);
    console.log("selectedUsers: " + JSON.stringify(selectedUsers));
    if (value && value !== NO_RESULTS) {
      // eslint-disable-next-line no-console
      setSelectedUsers(
        selectedUsers.includes(value) ? selectedUsers.filter((selection) => selection !== value) : [...selectedUsers, value]
      );
    }
    setInputValue("");
    onToggleClick();
//    textInputRef.current.focus();
  };

  const onTextInputChange = (_event, value) => {
    setInputValue(value);
    resetActiveAndFocusedItem();
  };

  const handleMenuArrowKeys = (key) => {
    let indexToFocus = 0;
    if (!isOpen) {
      setIsOpen(true);
    }
    if (selectOptions.every((option) => option.isDisabled)) {
      return;
    }
    if (key === 'ArrowUp') {
      // When no index is set or at the first index, focus to the last, otherwise decrement focus index
      if (focusedItemIndex === null || focusedItemIndex === 0) {
        indexToFocus = selectOptions.length - 1;
      } else {
        indexToFocus = focusedItemIndex - 1;
      }
      // Skip disabled options
      while (selectOptions[indexToFocus].isDisabled) {
        indexToFocus--;
        if (indexToFocus === -1) {
          indexToFocus = selectOptions.length - 1;
        }
      }
    }
    if (key === 'ArrowDown') {
      // When no index is set or at the last index, focus to the first, otherwise increment focus index
      if (focusedItemIndex === null || focusedItemIndex === selectOptions.length - 1) {
        indexToFocus = 0;
      } else {
        indexToFocus = focusedItemIndex + 1;
      }
      // Skip disabled options
      while (selectOptions[indexToFocus].isDisabled) {
        indexToFocus++;
        if (indexToFocus === selectOptions.length) {
          indexToFocus = 0;
        }
      }
    }
    setActiveAndFocusedItem(indexToFocus);
  };

  const onInputKeyDown = (event) => {
    const focusedItem = focusedItemIndex !== null ? selectOptions[focusedItemIndex] : null;
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'Enter':
        if (isOpen && focusedItem && focusedItem.value !== NO_RESULTS && !focusedItem.isAriaDisabled) {
          onSelect(focusedItem.value);
        }
        if (!isOpen) {
          setIsOpen(true);
        }
        break;
      case 'ArrowUp':
      case 'ArrowDown':
        event.preventDefault();
        handleMenuArrowKeys(event.key);
        break;
    }
  };

  const onToggleClick = () => {
    if (inputValue.length === 0) {
      setIsOpen(false);
    } else 
      setIsOpen(!isOpen);
    textInputRef?.current?.focus();
  };

  const onClearButtonClick = () => {
    setSelectedUsers([]);
    setInputValue("");
    resetActiveAndFocusedItem();
    textInputRef?.current?.focus();
  };

  const toggle = (toggleRef) => (
    <MenuToggle
      aria-label="Multi typeahead menu toggle"
      innerRef={toggleRef}
      isExpanded={isOpen}
      isFullWidth
      variant="typeahead"
      onClick={onToggleClick}
    >
      <TextInputGroup isPlain>
        <TextInputGroupMain
          id="multi-typeahead-select-input"
          aria-controls="select-multi-typeahead-listbox"
          autoComplete="off"
          innerRef={textInputRef}
          isExpanded={isOpen}
          placeholder={placeholder}
          role="combobox"
          value={inputValue}
          { ...(activeItemId && { 'aria-activedescendant': activeItemId })}
          onChange={onTextInputChange}
          onClick={onInputClick}
          onKeyDown={onInputKeyDown}
        >
          <ChipGroup aria-label="Current selections">
            {
              selectedUsers.map((selection, index) => (
                <Chip
                  key={index}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    onSelect(selection);
                  }}
                >
                  <Bullseye>
                    <AvatarComponent user={selection} size="sm" style={{ marginRight: '4px' }} />
                    { selection.first_name + ' ' + selection.surname }
                  </Bullseye>
                </Chip>
              ))
            }
          </ChipGroup>
        </TextInputGroupMain>
        <TextInputGroupUtilities { ...(selectedUsers.length === 0 ? { style: { display: 'none' } } : {})}>
          <Button variant="plain" onClick={onClearButtonClick} aria-label="Clear Input Value">
            <TimesIcon aria-hidden />
          </Button>
        </TextInputGroupUtilities>
      </TextInputGroup>
    </MenuToggle>
  );

  return (
    <Select
      id="multi-typeahead-select"
      isOpen={isOpen}
      selected={selectedUsers}
      shouldFocusFirstItemOnOpen={false}
      toggle={toggle}
      onOpenChange={(isOpen) => { !isOpen && closeMenu(); }}
      onSelect={(_event, selection) => onSelect(selection)}
    >
      <SelectList isAriaMultiselectable id="select-multi-typeahead-listbox">
        {
          selectOptions.map((option, index) => {
            if (option.id !== user.id) {
              return (
              <SelectOption
                id={`option-${option.id}`}
                key={`key-${option.id}`}
                isFocused={focusedItemIndex === index}
                ref={null}
                value={ option }
                isDisabled={option.isAriaDisabled}
              >
                { `${option.first_name} ${option.surname}` }
              </SelectOption>
              )
            } else { 
              return (<></>)
            }
          })
        }
      </SelectList>    
    </Select>
  );

};