import * as React from "react";
import { Breadcrumb, BreadcrumbItem, Bullseye, Button } from "@patternfly/react-core";
import { DataList } from "@patternfly/react-core";
import { Icon, PageBreadcrumb, PageGroup, PageSection } from "@patternfly/react-core";
import { Spinner, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem, Tooltip } from "@patternfly/react-core";
import { EnterpriseIcon, PlusIcon, SyncIcon } from "@patternfly/react-icons";

import { get_clean } from "../../api/api";
import { OrganizationContext } from "../../common/contexts/OrganizationContext";
import { PageHeadingComponent } from "../../components/PageHeadingComponent";
import { OrganizationModal } from "./OrganizationModal";
import { OrganizationDataListItem } from "./OrganizationDataListItem";

export const Organizations = () => {

  const ctx = React.useContext(OrganizationContext);

  const [loading, setLoading] = React.useState(true);
  const [organizations, setOrganizations] = React.useState([]);
  const [organization, setOrganization] = React.useState(null);
  const [action, setAction] = React.useState("create");
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const [isActionOpen, setIsActionOpen] = React.useState(0);

  const fetchOrganizations = async () => {
    setLoading(true);
    try {
      const response = await get_clean(`/organizations/getOrganizations/all`);
      setOrganizations(response.data);
      setLoading(false);
    } catch (error) {
      console.error(JSON.stringify(error));
      setLoading(false);
    }
  };

  React.useEffect(() => {

    const firstFetch = async () => {
      fetchOrganizations();
    };

    if (ctx?.id) {
      firstFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.id]);

  const onActionSelect = () => {
    setIsActionOpen(0);
  };

  const onActionToggle = (id) => {
    if (isActionOpen === id)
      setIsActionOpen(0)
    else
      setIsActionOpen(id);
  };

  const onModalToggle = (e) => {
    setIsModalOpen(!isModalOpen);
  };

  const onAddOrganization = () => {
    setOrganization(null);
    setAction("create");
    onModalToggle();
  };

  const onClose = () => {
    onModalToggle();
  };

  const onDeleteOrganization = (id) => {
    setOrganization(organizations.find(item => item.id === id));
    setAction("delete");
    onModalToggle();
  };

  const onEditOrganization = (id) => {
    setOrganization(organizations.find(item => item.id === id));
    setAction("update");
    onModalToggle();
  };

  const onRefreshOrganizations = () => {
    fetchOrganizations();
  };

  const onRefreshOrganization = (organization) => {
    onRefreshOrganizations();
    onModalToggle();
  };

  const onViewOrganization = (id) => {
    setOrganization(organizations.find(item => item.id === id));
    setAction("view");
    onModalToggle();
  };

  const renderToolbar = (
    <Toolbar>
      <ToolbarContent>
        <ToolbarGroup>
          <ToolbarItem variant="label">
            Organizations
          </ToolbarItem>
        </ToolbarGroup>
        <ToolbarGroup align={{default: 'alignRight' }}>
          <ToolbarItem variant="separator" />
          <ToolbarItem>
            <Tooltip content="Create new Organization">
              <Button variant="plain" onClick={onAddOrganization}><Icon><PlusIcon /></Icon></Button>
            </Tooltip>
          </ToolbarItem>
          <ToolbarItem>
            <Tooltip content="Refresh List">
              <Button variant="plain" onClick={onRefreshOrganizations}><Icon><SyncIcon /></Icon></Button>
            </Tooltip>
          </ToolbarItem>
        </ToolbarGroup>
      </ToolbarContent>
    </Toolbar>
  );

  if (loading) {
    return (
      <Bullseye height="100vh">
        <Spinner size="xl" />
      </Bullseye>
    );
  }

  return (
    <React.Fragment>
      <PageGroup stickyOnBreakpoint={{ default: 'top' }}>
        <PageBreadcrumb>
          <Breadcrumb>
            <BreadcrumbItem to="/">Dashboard</BreadcrumbItem>
            <BreadcrumbItem isActive>Administration</BreadcrumbItem>
            <BreadcrumbItem isActive>Organizations</BreadcrumbItem>
          </Breadcrumb>
        </PageBreadcrumb>
        <PageHeadingComponent title="Organizations" subtitle="Administer the organizations used in the application." icon={<Icon><EnterpriseIcon /></Icon>} />
      </PageGroup>
      <PageSection>
        {
          renderToolbar
        }
        <DataList isCompact>
          {
            organizations.map((item) => (
              <OrganizationDataListItem
                key={item.id}
                organization={item}
                isOpen={isActionOpen}
                setIsOpen={setIsActionOpen}
                onSelect={onActionSelect}
                onToggle={onActionToggle}
                onEdit={onEditOrganization}
                onDelete={onDeleteOrganization}
                onView={onViewOrganization}
              />
            ))
          }
        </DataList>
      </PageSection>
      <OrganizationModal isOpen={isModalOpen} onClose={onClose} organization={organization} organizationId={ctx.id} action={action} onRefresh={onRefreshOrganization} />
    </React.Fragment>
  );

};
