import * as React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Brand } from "@patternfly/react-core";
import { Divider, Dropdown, DropdownItem, DropdownList, Icon } from "@patternfly/react-core";
import { Masthead, MastheadBrand, MastheadContent, MastheadMain, MastheadToggle, MenuToggle } from "@patternfly/react-core";
import { Nav, NavExpandable, NavItem, NavList, NotificationBadge } from "@patternfly/react-core";
import { Page, PageSidebar, PageSidebarBody, PageToggleButton } from "@patternfly/react-core";
import { SkipToContent, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem, Tooltip } from "@patternfly/react-core";
import { BarsIcon, BellIcon, BullhornIcon, ClipboardCheckIcon, CogIcon, EllipsisVIcon, EnterpriseIcon, ExportIcon, FlagIcon, HomeIcon, ImportIcon, QuestionCircleIcon } from "@patternfly/react-icons";
import { PackageIcon, PowerOffIcon, TagIcon, TrophyIcon, UserIcon, UsersIcon } from "@patternfly/react-icons";

import ShoutOut from "./assets/brands/ShoutOut Light.png";

import { useAuth } from "./common/contexts/AuthContext";
import { NotificationContext } from "./common/contexts/NotificationContext";
import { get } from "./api/api";
import { hasAdminPermission, hasSuperAdminPermission } from "./utils/Utilities";
import { AboutModalComponent } from "./components/AboutModalComponent";
import { AvatarComponent } from "./components/AvatarComponent";
import { Alerts } from "./Alerts";
import { Notifications } from "./Notifications";

const AppLayout = ({ user, organisation, children }) => {

  const { logout } = useAuth();
  const { notifications, setNotifications } = React.useContext(NotificationContext);
  const location = useLocation();

  const drawerRef = React.useRef(null);

  // const NOTIFICATION_REFRESH_INTERVAL = 5000;

  //  const [debug] = React.useState(false);
  //  const [sidebarOpen, setSidebarOpen] = React.useState(true);
  const [isAboutModalOpen, setIsAboutModalOpen] = React.useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [isMobileOpen, setIsMobileOpen] = React.useState(false);
  const [isMobileView, setIsMobileView] = React.useState(false);
  const [isSidebarOpenDesktop, setIsSidebarOpenDesktop] = React.useState(true)
  const [isSidebarOpenMobile, setIsSidebarOpenMobile] = React.useState(false);
  // const [notifications, setNotifications] = React.useState([]);
  const [isNotificationsLoading, setIsNotificationsLoading] = React.useState(false);
  const [isDrawerExpanded, setIsDrawerExpanded] = React.useState(false);
  // const [overflowMessage, setOverflowMessage] = React.useState("")

  const onSidebarToggleDesktop = () => { setIsSidebarOpenDesktop((prevState) => !prevState); }
  const onSidebarToggleMobile = () => { setIsSidebarOpenMobile((prevState) => !prevState); }
  const onCloseDrawer = (event) => { setIsDrawerExpanded((prevState) => !prevState); }

  const toggleAboutModal = (_event) => {
    setIsAboutModalOpen(!isAboutModalOpen);
  };

  const onDropdownSelect = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onMobileSelect = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  const onMobileToggle = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  const onPageResize = (_event, { mobileView }) => {
    setIsMobileView(mobileView);
  };

  const getNumberUnread = () => {
    if (!notifications) {
      return 0;
    }
    return notifications.filter((item) => item.has_seen_YN ? item.has_seen_YN === 0 : !item.isNotificationRead).length;
  };

  const focusDrawer = (event) => {
    if (drawerRef.current === null) {
      return;
    }
    const firstTabbableItem = drawerRef.current.querySelector('a, button');
    firstTabbableItem?.focus();
  };

  const onUpdateNotification = (updatedNotification) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) => (notification.id === updatedNotification.id ? updatedNotification : notification))
    );
  };

  const fetchNotifications = async () => {
    setIsNotificationsLoading(true);
    try {
      const response = await get(organisation.id, "/notifications/users/" + user.id);
      setNotifications(response.data);
      setIsNotificationsLoading(false);
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  };

//  useInterval(async() => {
//    if (debug) localLog("Checking for new notifications ...");
//    fetchNotifications();
//  }, NOTIFICATION_REFRESH_INTERVAL);

  // React.useEffect(() => {
  //   fetchNotifications();
  //   // eslint-disable-next-line
  // }, []);

  // React.useEffect(() => {
  //   setOverflowMessage(buildOverflowMessage());
  // }, [maxDisplayed, notifications, alerts])

  const UserDropdownList = (
    <React.Fragment>
      <DropdownItem key="user-profile" to="/me"><Icon><UserIcon /></Icon>&nbsp;Profile</DropdownItem>
{/*
      <DropdownItem key="user-settings" to="/settings">Settings</DropdownItem>
*/}
      <Divider />
      <DropdownItem key="about" onClick={(e) => toggleAboutModal(e)}>About</DropdownItem>
      <DropdownItem key="user-logout" onClick={logout}><Icon><PowerOffIcon /></Icon>&nbsp;Logout</DropdownItem>
    </React.Fragment>
  );

  const HeaderToolbar = (
    <Toolbar isFullHeight isStatic>
      <ToolbarContent>
        <ToolbarGroup align={{ default: 'alignRight' }} spaceItems={{ default: 'spaceItemsMd' }}>
          <ToolbarGroup variant="icon-button-group">
            <ToolbarItem visibility={{ default: 'visible' }} selected={isDrawerExpanded}>
              <NotificationBadge
                aria-label="Notifications"
                isExpanded={isDrawerExpanded}
                variant={getNumberUnread() === 0 ? 'read' : 'unread'}
                onClick={(event) => onCloseDrawer(event)}
                count={getNumberUnread()}
              >
                <BellIcon />
              </NotificationBadge>
            </ToolbarItem>
          </ToolbarGroup>
          <ToolbarGroup variant="icon-button-group">
            <ToolbarItem>
              <Tooltip content="Contact Support" spacer={{ default: 'spacerMd' }}>
                <a
                  href="mailto:shoutoutservicerequests@altron.com"
                  style={{  color: 'inherit' }}
                >
                  <QuestionCircleIcon />
                </a>
              </Tooltip>
            </ToolbarItem>
          </ToolbarGroup>
          <ToolbarItem visibility={{ default: 'visible', md: 'hidden' }}>
            <Dropdown
              isOpen={isMobileOpen}
              onSelect={onMobileSelect}
              onOpenChange={(isOpen) => setIsMobileOpen(isOpen)}
              popperProps={{ position: 'right' }}
              toggle={(toggleRef) => (
                <MenuToggle
                  ref={toggleRef}
                  onClick={onMobileToggle}
                  isExpanded={isMobileOpen}
                  variant="plain"
                  aria-label="Mobile Menu"
                >
                  <EllipsisVIcon aria-hidden="true" />
                </MenuToggle>
              )}
            >
              <DropdownList>
                { UserDropdownList }
              </DropdownList>
            </Dropdown>
          </ToolbarItem>
          <ToolbarItem key="toolbar-user" align={{ default: 'alignRight' }} spacer={{ default: 'spacerMd' }} visibility={{ default: 'hidden', md: 'visible' }}>
            <Dropdown
              isOpen={isDropdownOpen}
              onSelect={onDropdownSelect}
              onOpenChange={(isOpen) => setIsDropdownOpen(isOpen)}
              popperProps={{ position: 'right' }}
              toggle={(toggleRef) => (
                <MenuToggle
                  ref={toggleRef}
                  onClick={onDropdownToggle}
                  isFullHeight
                  isExpanded={isDropdownOpen}
                  icon={<AvatarComponent user={user} />}
                >
                  { user.first_name + " " + user.surname }
                </MenuToggle>
              )}
            >
              <DropdownList>
                { UserDropdownList }
              </DropdownList>
            </Dropdown>
          </ToolbarItem>
        </ToolbarGroup>
      </ToolbarContent>
    </Toolbar>
  );

  const Header = (
    <Masthead>
      <MastheadToggle>
        <PageToggleButton aria-label="Global Navigation" variant="plain" onSidebarToggle={isMobileView ? onSidebarToggleMobile : onSidebarToggleDesktop}>
          <BarsIcon />
        </PageToggleButton>
      </MastheadToggle>
      <MastheadMain>
        <MastheadBrand>
          <Brand src={ShoutOut} alt="ShoutOut Logo" heights={{ default: '32px' }} onClick={toggleAboutModal} />
        </MastheadBrand>
      </MastheadMain>
      <MastheadContent>
        { HeaderToolbar }
      </MastheadContent>
    </Masthead>
  );

  const Navigation = (
    <Nav id="navigation" theme="dark">
      <NavList id="navigation-list">
        <NavItem key="navigation-home" isActive={location.pathname === "/"}><NavLink exact="true" to="/"><Icon><HomeIcon /></Icon>&nbsp;Dashboard</NavLink></NavItem>
        <NavItem key="navigation-send" isActive={location.pathname === "/send"}><NavLink exact="true" to="send"><Icon><BullhornIcon /></Icon>&nbsp;Send ShoutOut</NavLink></NavItem>
        <NavExpandable key="navigation-shoutouts" title={<><BullhornIcon /> ShoutOuts</>} isActive={location.pathname.startsWith("/shoutouts/")} isExpanded={location.pathname.startsWith("/shoutouts/")}>
          <NavItem key="navigation-from" isActive={location.pathname === "/shoutouts/from"}><NavLink exact="true" to="/shoutouts/from"><Icon><ExportIcon /></Icon>&nbsp;ShoutOuts From Me</NavLink></NavItem>
          <NavItem key="navigation-to" isActive={location.pathname === "/shoutouts/to"}><NavLink exact="true" to="/shoutouts/to"><Icon><ImportIcon /></Icon>&nbsp;ShoutOuts To Me</NavLink></NavItem>
        </NavExpandable>
        <NavItem key="navigation-leaderboard" isActive={location.pathname === "/leaderboard"}><NavLink exact="true" to="/leaderboard"><Icon><TrophyIcon /></Icon>&nbsp;Leaderboard</NavLink></NavItem>
        {
          (hasAdminPermission(user) || hasSuperAdminPermission(user))
          ? (
              <NavExpandable key="nav-admin" title={<><Icon><CogIcon /></Icon> Administration</>} isActive={location.pathname.startsWith("/administration")} isExpanded={location.pathname.startsWith("/administration/")}>
                <NavItem key="nav-admin-categories" isActive={location.pathname === "/administration/categories"}><NavLink exact="true" to="/administration/categories"><Icon><TagIcon /></Icon>&nbsp;Categories</NavLink></NavItem>
                <NavItem key="nav-admin-competitions" isActive={location.pathname === "/administration/competitions"}><NavLink exact="true" to="/administration/competitions"><Icon><PackageIcon /></Icon>&nbsp;Competitions</NavLink></NavItem>
                <NavItem key="nav-admin-departments" isActive={location.pathname === "/administration/departments"}><NavLink exact="true" to="/administration/departments"><Icon><TagIcon /></Icon>&nbsp;Departments</NavLink></NavItem>
                {
                  hasSuperAdminPermission(user)
                  ? <NavItem key="nav-admin-organizations" isActive={location.pathname === "/administration/organizations"}><NavLink exact="true" to="/administration/organizations"><Icon><EnterpriseIcon /></Icon>&nbsp;Organizations</NavLink></NavItem>
                  : null
                }
                {/* {
                  hasSuperAdminPermission(user)
                  ? <NavItem key="nav-admin-organizationconfig" isActive={location.pathname === "/administration/organizationconfig"}><NavLink exact="true" to="/administration/organizationconfig"><Icon><EnterpriseIcon /></Icon>&nbsp;Organization Config</NavLink></NavItem>
                  : null
                } */}
                <NavItem key="nav-admin-permissions" isActive={location.pathname === "/administration/permissions"}><NavLink exact="true" to="/administration/permissions"><Icon><ClipboardCheckIcon /></Icon>&nbsp;Permissions</NavLink></NavItem>
                <NavItem key="nav-admin-roles" isActive={location.pathname === "/administration/roles"}><NavLink exact="true" to="/administration/roles"><Icon><FlagIcon /></Icon>&nbsp;Roles</NavLink></NavItem>
                <NavItem key="nav-admin-users" isActive={location.pathname === "/administration/users"}><NavLink exact="true" to="/administration/users"><Icon><UsersIcon /></Icon>&nbsp;Users</NavLink></NavItem>
              </NavExpandable>
          )
          : null
        }
      </NavList>
    </Nav>
  );

  const Sidebar = (
    <PageSidebar isSidebarOpen={isMobileView ? isSidebarOpenMobile : isSidebarOpenDesktop}>
      <PageSidebarBody>
        {
          Navigation
        }
      </PageSidebarBody>
    </PageSidebar>
  );

  const pageId = 'primary-app-container';

  const PageSkipToContent = (
      <SkipToContent
          onClick={(event) => {
              event.preventDefault();
              const primaryContentContainer = document.getElementById(pageId);
              primaryContentContainer && primaryContentContainer.focus();
          }}
          href={`#${pageId}`}
      >
          Skip to Content
      </SkipToContent>
  );

  return (
    <React.Fragment>
      <Page
        mainContainerId={pageId}
        header={Header}
        isNotificationDrawerExpanded={isDrawerExpanded}
        notificationDrawer={
          <Notifications
            notifications={notifications}
            update={onUpdateNotification}
            drawerRef={drawerRef}
            getNumberUnread={getNumberUnread}
            onCloseDrawer={onCloseDrawer}
            refresh={fetchNotifications}
            loading={isNotificationsLoading}
          />
        }
        sidebar={Sidebar}
        skipToContent={PageSkipToContent}
        onNotificationDrawerExpand={(event) => focusDrawer(event)}
        onPageResize={onPageResize}
        groupProps={{ stickyOnBreakpoint: { default: 'top' } }}
      >
      <Alerts />
        {children}
      </Page>
      <AboutModalComponent isOpen={isAboutModalOpen} toggleOpen={toggleAboutModal} />
    </React.Fragment>
  );

};

export { AppLayout }